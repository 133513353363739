import React from 'react'
import '../styles/image-header.scss'

const ImageHeader = (props) => {
    const imageStyle = {
        backgroundImage: `linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,0.1)), url(${props.image})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    }

    return (
        <div className="image-header" style={imageStyle}>
            <div className="image-header__container container">
                <h1>{props.title}</h1>
            </div>
        </div>
    )
}

export default ImageHeader