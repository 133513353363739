import React, {useEffect} from 'react'
import {graphql, useStaticQuery} from "gatsby"
import '../styles/facets.scss'
import '../styles/index-filter.scss'

const IndexFilter = (props) => {
    //const [types, setTypes] = useState([])
    const data = useStaticQuery(graphql`
        query allTypesIndex {
            allWpResourceType(filter: {count: {gt: 0}}) {
                nodes {
                    slug
                    name
                }
            }
        }
    `)

    useEffect(() => {
        typePopulate(data.allWpResourceType.nodes)
    }, [])

    function typePopulate(items) {
        const typeDropdown = document.getElementById('typeSelect')
        const typeValue = typeDropdown.value
        const typeOptions = document.querySelectorAll('#typeSelect option')
        let options = []

        items.forEach(function (item, index) {
            options.push(item)
        })

        const newOptionTest = new Set()

        const newOptions = options.reduce((newArr, option) => {
            if (!newOptionTest.has(option.name)) {
                newOptionTest.add(option.name, option)
                newArr.push(option)
            }
            return newArr
        }, [])
        typeOptions.forEach(o => o.remove())
        let newOption = document.createElement('option')
        newOption.textContent = "All Resources"
        newOption.value = "all"
        typeDropdown.appendChild(newOption)
        newOptions.forEach(function (option, index) {
            let newOption = document.createElement('option')
            newOption.textContent = option.name
            newOption.value = option.slug
            typeDropdown.appendChild(newOption)
        })
        if (typeValue !== 'all') {
            typeDropdown.value = typeValue
        }
    }

    function typeChange(event) {
        const typeValue = event.target.value
        const sortOptions = document.querySelectorAll('#sortList li a')

        sortOptions.forEach(function(option, index) {
            if (option.getAttribute('data-option') === 'topics') {
                option.href = '/resources/' + typeValue + '/by-topic'
            } else if (option.getAttribute('data-option') === 'scriptures') {
                option.href = '/resources/' + typeValue + '/by-scripture'
            } else if (option.getAttribute('data-option') === 'perspectives') {
                option.href = '/resources/' + typeValue + '/by-perspective'
            } else if (option.getAttribute( 'data-option') === 'recent') {
                option.href = '/resources/' + typeValue
            }
        })
    }

    return (
        <div className="facets facets--index">
            <div className="container facets--index__inner">
                <div className="facets__input-group facets__input-group--horiz">
                    <label htmlFor="typeSelect">Browse:</label>
                    <div className="facets__input-group__input-wrapper--select">
                        <select name="resource-type" id="typeSelect" onChange={typeChange}>
                            <option value="all">All Resources</option>
                            <option value="devotionals">Devotionals</option>
                            <option value="bible-studies">Bible Studies</option>
                            <option value="articles">Articles</option>
                        </select>
                    </div>
                </div>
                <div className="facets__input-group facets__input-group--horiz">
                    <label htmlFor="typeSelect">Sorted By:</label>
                    <ul className="facets__input-group__list" id="sortList">
                        <li>
                            <a data-option="recent" href="/resources/">Recent</a>
                        </li>
                        <li>
                            <a data-option="topics" href="/resources/topics">Topic</a>
                        </li>
                        <li>
                            <a data-option="scriptures" href="/resources/scripture">Scripture</a>
                        </li>
                        <li>
                            <a data-option="perspectives" href="/resources/perspectives">Perspective</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default IndexFilter

