import * as React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import ImageHeader from '../components/ImageHeader'
import {graphql} from 'gatsby'
import IndexFilter from '../components/IndexFilter'
import Layout from "../components/Layout"
import Seo from '../components/Seo'

import '../styles/index.scss'

const PerspectivesPage = ({data}) => {

    return (
        <Layout>
            <Seo
                title={`${data.wpResourceType.name}  by Perspective`}
                description={`Index for ${data.wpResourceType.name} by Perspective`}
            />
            <ImageHeader title={`${data.wpResourceType.name} by Perspective`}
                         image={data.wp.resourcesGeneralSettings.resourceLibraryHeader.headerImage.sourceUrl}/>
            <Breadcrumbs title="Perspectives" isResource={true} />
            <IndexFilter taxonomy="perspectives" />
            <section className="index container">
                <div className="index__group">
                    <ul className="index__group__children">
                        {data.allWpPerspective.nodes.filter((term) => term.count > 0).map((term, index) =>
                            <li key={index} className="index__group__children__child">
                                <a href={`/resources/perspectives/${term.slug}/${data.wpResourceType.slug}`}>
                                    <div>
                                        {term.name}
                                    </div>
                                    <span>
                                            {`${term.count.toString()} resources`}
                                        </span>
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </section>
        </Layout>
    )
}

export default PerspectivesPage

export const query = graphql`
    query ($type: String!) {
        allWpPerspective(
            filter: {mereResources: {nodes: {elemMatch: {resourceTypes: {nodes: {elemMatch: {slug: {eq: $type}}}}}}}}
        ) {
            nodes {
                name
                id
                slug
                count
            }
        }
        wpResourceType(slug: {eq: $type}) {
            name
            slug
        }
        wp {
            resourcesGeneralSettings {
                resourceLibraryHeader {
                    headerImage {
                        sourceUrl
                    }
                }
            }
        }
    }
`