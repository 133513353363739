import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronRight} from '@fortawesome/pro-light-svg-icons'
import '../styles/breadcrumbs.scss'

const Breadcrumbs = (props) => {
    return (
        <div className="breadcrumbs">
            <ul className="breadcrumbs__list container">
                <li>
                    <a href="/">Home</a>
                </li>
                <li className="separator">
                    <FontAwesomeIcon icon={faChevronRight} size="lg"/>
                </li>
                {props.isResource &&
                <>
                    <li>
                        <a href="/resources">Resources</a>
                    </li>
                    <li className="separator">
                        <FontAwesomeIcon icon={faChevronRight} size="lg"/>
                    </li>
                </>

                }
                {props.termType && props.termType !== 'Resource_type' && props.termType !== 'Perspective' && props.termType !== 'Multiauthor' &&
                <>
                    <li>
                        <a href={props.termUri}>{props.termType}</a>
                    </li>
                    <li className="separator">
                        <FontAwesomeIcon icon={faChevronRight} size="lg"/>
                    </li>
                </>
                }
                {props.termType && props.termType === 'Perspective' &&
                <>
                <li>
                <a href="/resources/perspectives">Perspective</a>
                    </li>
                    <li className="separator">
                    <FontAwesomeIcon icon={faChevronRight} size="lg"/>
                    </li>
                    </>
                }
                {props.termType && props.termType === 'Multiauthor' &&
                <>
                    <li>
                        <a href="/resources/authors">Author</a>
                    </li>
                    <li className="separator">
                        <FontAwesomeIcon icon={faChevronRight} size="lg"/>
                    </li>
                </>
                }
                {props.type &&
                <>
                    <li>
                        <a href={'/resources/' + props.type[0].slug}>{props.type[0].name}</a>
                    </li>
                    <li className="separator">
                        <FontAwesomeIcon icon={faChevronRight} size="lg"/>
                    </li>
                </>
                }
                <li>
                    <span>{props.title}</span>
                </li>
            </ul>
        </div>
    )
}

export default Breadcrumbs